<template>
  <v-data-table
    :headers="headers"
    :items="policies"
    :items-per-page="5"
    class="elevation-1"
    :multi-sort="true"
  ></v-data-table>
</template>

<script>
export default {
  name: 'PoliciesTable',
  props: {
    policies: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    headers: [
      { text: 'Policy ID', value: 'id' },
      { text: 'Company', value: 'company' },
      { text: 'Company Country', value: 'companyCountry' },
      { text: 'Intermediary', value: 'intermediary' },
      { text: 'Intermediary Country', value: 'intermediaryCountry' },
    ],
  }),
  methods: {},
}
</script>

<style lang="scss" scoped></style>
