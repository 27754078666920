<template>
  <v-container>
    <v-row align-content="center">
      <v-col cols="12">
        <PoliciesTable :policies="policies" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PoliciesTable from '../components/PoliciesTable.vue'

import policiesData from '../utils/policies.json'
export default {
  components: {
    PoliciesTable,
  },
  data() {
    return {
      policies: policiesData,
    }
  },
}
</script>

<style lang="scss" scoped></style>
